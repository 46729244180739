import axios from 'axios'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(async (config) => {
  const token = await authToken.get()
  config.headers.Authorization = `Bearer ${token.access_token}`
  return config
})

export default instance
