import nl from '@/i18n/languages/nl.json'
import fr from '@/i18n/languages/fr.json'

const translations = {
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: {
    nl: nl,
    fr: fr
  }
}

export default translations
