<template>
  <header>
    <b-container id="menu-holder">
      <div class="region region-header">
        <b-navbar>
          <b-navbar-brand href="/">
            <img :src="logo" width="188" />
          </b-navbar-brand>
          <LocaleSwitcher />
        </b-navbar>
      </div>
    </b-container>
  </header>
</template>

<script>

import LocaleSwitcher from "./LocaleSwitcher";

export default {
  name: "Navbar",
  components: {LocaleSwitcher},
  data: () => {
    return {
      logo: require('./../assets/logo.svg')
    }
  }
}
</script>

<style scoped>

header {
  background-color:#FFFFFF;
  padding:14px 0px;
  border-bottom:1px solid #e0e0dc;
}

.navbar-light .navbar-nav .nav-link {
  color: #404957;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  outline:none;
  box-shadow: none;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:focus {
  outline:none;
  box-shadow: none;
  font-weight:600;
}

.navbar-light .navbar-nav .nav-link:visited {
  outline:none;
  box-shadow: none;
}

nav {
  width:100%;
}

.navbar-nav {
  float:right !important;
  position:absolute;
  right:0;
}

.navbar-light .navbar-toggler {
  color: #940639 !important;
  border:none;
}
</style>
