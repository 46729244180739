<template>
  <div id="app">
    <navbar />
    <router-view />
    <Footer :lang="this.$i18n.locale" />
  </div>
</template>

<script>

import Navbar from "./components/NavBar";
import Footer from "./components/Footer";

export default {
  name: 'App',
  components: {Footer, Navbar}
}
</script>
