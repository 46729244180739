<template>
  <div id="Home">
    <b-container>
      <div class="button-group tabs">
        <b-container v-if="this.$i18n.locale === 'fr'">
          <button class="button" :class="[sortOption==='title' && sortDirection==='asc' ? 'is-checked' : '']" @click="sort('title', 'asc')">Tri alphabétique croissant</button>
          <button class="button" :class="[sortOption==='title' && sortDirection==='desc' ? 'is-checked' : '']" @click="sort('title', 'desc')">Tri alphabétique décroissant</button>
          <button class="button" :class="[sortOption==='random'? 'is-checked' : '']" @click="sort('random')">Tri aléatoire</button>
        </b-container>
        <b-container v-if="this.$i18n.locale === 'nl'">
          <button class="button" :class="[sortOption==='title' && sortDirection==='asc' ? 'is-checked' : '']" @click="sort('title', 'asc')">Alfabetisch sorteren oplopend</button>
          <button class="button" :class="[sortOption==='title' && sortDirection==='desc' ? 'is-checked' : '']" @click="sort('title', 'desc')">Alfabetisch sorteren aflopend</button>
          <button class="button" :class="[sortOption==='random'? 'is-checked' : '']" @click="sort('random')">Willekeurige sortering</button>
        </b-container>
      </div>
      <div class="grid">
        <isotope ref="cpt" class="company-block row home" :options='option' :list="companies" :item-selector="'grid-item'"
                 @filter="filterOption=arguments[0]" @sort="sortOption=arguments[0]">
          <div class="block" v-for="company, index in companies" :key="index">
            <!-- <p>name : {{company.name}}<br>title : {{company.title}}<br>id : {{company.id}}</p> -->
            <ListCard :company="company"></ListCard>
          </div>
        </isotope>
      </div>
    </b-container>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import ListCard from "./companies/ListCard";
import isotope from "vueisotope";

export default {
  name: "Home",
  components: {
    ListCard,
    isotope
  },
  data () {
    return {
      companies: [],
      language: this.$i18n.locale,
      sortOption: "id",
      sortDirection: 'asc',
      currentLayout: 'masonry',
      filterOption: "show all",
      option: {
        itemSelector: ".grid-item",
        getSortData: {
          id: "id",
          name: function(itemElem){
            return itemElem.name.toLowerCase();
          }
        },
        getFilterData:{
          isEven: function(itemElem){
            return itemElem.id % 2 === 0;
          },
          isOdd: function(itemElem){
            return itemElem.id % 2 !== 0;
          },
          filterByText: function(itemElem){
            return itemElem.name.toLowerCase().includes(this.filterText.toLowerCase());
          }
        }
      }
    }
  },
  methods: {
    validateCompany: function(company) {
      if (
        company.information.intro_image !== undefined &&
        company.information.intro_content !== undefined &&
        company.information.intro_image.NL !== undefined &&
        company.information.intro_content[this.language.toUpperCase()] !== undefined &&
        company.information.intro_image.NL.content !== '' &&
        company.information.intro_content[this.language.toUpperCase()].content !== ''
      ) {
        return true
      }
      return false
    },
    sort: function(key, direction = 'asc') {
      this.sortOption = key;
      this.sortDirection = direction;
      this.companies.sort(this.compare);
      //console.log(this.$refs.cpt);
    },
    compare: function(a, b) {
      var key = (this.sortOption)? this.sortOption : 'id';
      if (key == 'random') {
        var r = (Math.floor(Math.random() * 1000)) % 2;
        return (r == 1)? 1 : -1;
      }
      if (a[key] < b[key]) { return (this.sortDirection == 'asc')? -1 : 1; }
      if (a[key] > b[key]) { return (this.sortDirection == 'asc')? 1 : -1; }
      return 0;
    }
  },
  beforeMount() {
    EducamApi.get('/eov/company').then(response => {
      if (response.data.detail.total_items > 0) {
        response.data.detail._embedded.company.forEach((value) => {
          if (this.validateCompany(value)) {
            value.title = value.contact.title;
            this.companies.push(value)
          }
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>
.company-block {
  flex: unset;
}
.block {
  flex: unset;
  float: left;
  max-width: 500px;
  min-height: 500px;
  overflow: hidden;
}
.button {
  color: #888;
  background-color: transparent;
  border: 1px solid #888;
  font-weight: bold;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
}
.button.is-checked {
  color: #000;
  border-color: #000;
}
@media (min-width: 768px) {
  .block {
    width: 50%;
  }
  .button-group.tabs {
    border-bottom: 1px solid #000;
    padding-bottom: 0;
  }
  .button {
    color: #888;
    background-color: transparent;
    border: 1px solid;
    border-color: #888 #888 #000 #888;
    font-weight: bold;
    padding: 5px 10px;
    margin: 10px 10px -1px 10px;
  }
  .button.is-checked {
    border-color: #000 #000 #f8f6ee #000;
    color: #000;
  }
}
</style>

