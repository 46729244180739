<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3" class="text-center content-holder">
        <h1>404</h1>
        <h3 class="font-bold">{{ $t('PAGE_NOT_FOUND') }}</h3>

        <div class="error-desc">
          {{ $t('PAGE_NOT_FOUND_CONTENT') }}
          <div class="form-group mt-3">
            <a href="/" class="btn btn-primary">{{ $t('BACK_TO_HOME') }}</a>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
.content-holder {
  padding:100px 0px;
}
</style>
