import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/modules/error/PageNotFound'
import Home from '@/views/modules/Home'
import CompanyDetail from "../views/modules/companies/CompanyDetail"
import CourseDetail from "../views/modules/companies/CourseDetail"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/eov/:id',
    name: 'CompanyDetail',
    component: CompanyDetail
  },
  {
    path: '/course/:id/:company',
    name: 'CourseDetail',
    component: CourseDetail
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== 'unauthorized') {
    next()
  }
})

export default router
