<template>
    <b-navbar-nav right>
      <b-nav-item @click="switchLocale('nl')">NL</b-nav-item>
      <b-nav-item>|</b-nav-item>
      <b-nav-item @click="switchLocale('fr')">FR</b-nav-item>
    </b-navbar-nav>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  methods: {
    switchLocale (locale) {
      localStorage.setItem('language', locale)
      this.$i18n.locale = locale
      this.$router.go('/')
    }
  }
}
</script>

<style scoped>

</style>
