<template>
  <b-container>
    <b-row class="company-block">
      <b-col cols="12">
        <InfoCard @search="searchCourses" v-if="company" :search="true" :company="company" :more="false"></InfoCard>
      </b-col>
    </b-row>
    <b-row class="company-block-courses">
      <b-col cols="12">
        <h3>{{ $t('COURSES1') }}</h3>
        <div class="mb-3 totals">{{ $tc('COURSES', totalCourses).toLowerCase() }}</div>
      </b-col>
      <CourseCard v-for="(course, index) in courses" :key="index" :courseId="parseInt(index)" :company="parseInt(companyId)" :course="(course[language.toUpperCase()]) ? course[language.toUpperCase()] : null"></CourseCard>
    </b-row>
  </b-container>
</template>

<script>

import EducamApi from '@/axios/educam-api.instance'
import InfoCard from "./InfoCard";
import CourseCard from "./CourseCard"

export default {
  name: "CompanyDetail",
  components: {CourseCard, InfoCard},
  data () {
    return {
      company: false,
      language: this.$i18n.locale,
      totalCourses : 0,
      loaded: false,
      companyId: this.$route.params.id,
      courses: []
    }
  },
  beforeCreate: function () {
    EducamApi.get('/eov/company/' + this.$route.params.id).then(response => {
      this.company = response.data.detail
      this.courses = response.data.detail.courses

      for (const key in this.courses) {
        const content = this.courses[key][this.language.toUpperCase()]
        if (content && content.image !== "" && content.intro !== "" && content.title !== "") {
          this.totalCourses++
        }
      }
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    searchCourses(searchValue) {
      EducamApi.get('/eov/courses?company=' + this.$route.params.id + '&search=' + searchValue).then(response => {
        this.totalCourses = 0
        this.courses = {}
        for (const key in response.data.detail._embedded.courses) {
          const content = response.data.detail._embedded.courses[key].content[this.language.toUpperCase()]
          if (content && content.image !== "" && content.intro !== "" && content.title !== "") {
            content.image = response.data.detail._embedded.courses[key].image

            this.courses[response.data.detail._embedded.courses[key].id] = {
              [[this.language.toUpperCase()]]: content
            }

            this.totalCourses++
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.totals {
  color:#384659;
  font-weight:600;
}
.company-block-courses {
  margin-top: 50px;
}
</style>
