<template>
  <footer role="contentinfo">
    <b-container v-if="lang === 'nl'">
      <div class="region region-footer-menus">
        <nav role="navigation" aria-labelledby="block-voet-menu" id="block-voet" class="block block-menu navigation menu--footer">
          <div class="inner">
            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/nl/onze-opleidingen">Onze opleidingen</a>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/nl/ons-aanbod/leeroplossingen/technics-lab">Technics Lab</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/ons-aanbod/leeroplossingen/business-lab">Business Lab</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/onze-opleidingen">Alle opleidingen</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/ons-aanbod/leeroplossingen/opleidingskalender">Opleidingskalender</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/ons-aanbod/analyse-evaluatie">Analyse &amp; evaluatie</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/ons-aanbod/diensten">Diensten</a>
                </li>

              </ul>



            </div>


            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/nl/certificering" class="is-active">Certificering</a>
              </div>




              <div class="menu-title">
                <a href="https://www.educam.be/nl/sectorale-ondersteuning">Sectorale ondersteuning</a>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/nl/sectorale-ondersteuning/hr-beleid">HR-beleid</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/sectorale-ondersteuning/caos-steunmaatregelen">CAO's &amp; steunmaatregelen</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/sectorale-ondersteuning/leren-en-werken">Leren en werken</a>
                </li>

              </ul>



            </div>


            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/nl/kennis-delen">Kennis delen</a>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/nl/kennis-delen/projecten-partners">Projecten &amp; Partners</a>
                </li>

              </ul>






              <div class="menu-title">
                <span>Over ons</span>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/nl/wie-zijn-wij-missie-visie-en-waarden">Wie zijn wij?</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/over-ons/training-centers">Training Centers</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/over-ons/onze-trainers">Onze trainers</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/nl/over-ons/werken-bij-educam">Werken bij EDUCAM</a>
                </li>

              </ul>



            </div>


            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/nl/contact">Contact</a>
              </div>

            </div>





          </div>
        </nav>

      </div>

      <div class="region region-footer">
        <div id="block-socialblock" class="block block-educam-helper block-social-block">


          <ul class="social-menu">
            <li><a class="icon-linkedin" href="https://www.linkedin.com/company/educamservice" target="_blank">
              <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Footer-2" transform="translate(-898.000000, -464.000000)">
                    <g id="Group-7" transform="translate(898.000000, 464.000000)">
                      <rect class="circle" id="Rectangle-13-Copy-7" stroke="#FFFFFF" opacity="0.239653088" x="0.5" y="0.5" width="47" height="47" rx="23.5"></rect>
                      <g class="path" id="icons/24/linkedin" transform="translate(12.000000, 12.000000)" fill="#FFFFFF">
                        <path d="M3.6780613,1.04736328 C5.1275482,1.04736328 6.30461982,2.2244349 6.30461982,3.67477044 C6.30461982,5.12595463 5.1275482,6.30302625 3.6780613,6.30302625 C2.22263388,6.30302625 1.04895684,5.12595463 1.04895684,3.67477044 C1.04895684,2.2244349 2.22263388,1.04736328 3.6780613,1.04736328 L3.6780613,1.04736328 Z M1.40963128,22.8745313 L5.94479403,22.8745313 L5.94479403,8.29479777 L1.40963128,8.29479777 L1.40963128,22.8745313 Z" id="Fill-1"></path>
                        <path d="M8.78733288,8.29479777 L13.1307017,8.29479777 L13.1307017,10.2882666 L13.1926528,10.2882666 C13.7968886,9.1417462 15.2760781,7.93327469 17.4808597,7.93327469 C22.0677898,7.93327469 22.9155869,10.9519075 22.9155869,14.8785913 L22.9155869,22.8745313 L18.3863647,22.8745313 L18.3863647,15.7840963 C18.3863647,14.093594 18.3558134,11.918515 16.0305241,11.918515 C13.6729863,11.918515 13.3131605,13.7609249 13.3131605,15.6635886 L13.3131605,22.8745313 L8.78733288,22.8745313 L8.78733288,8.29479777 Z" id="Fill-4"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg></a></li>
            <li><a class="icon-facebook" href="https://www.facebook.com/EducamService/" target="_blank">
              <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Footer-2" transform="translate(-961.000000, -479.000000)">
                    <g id="Group-5" transform="translate(961.000000, 479.000000)">
                      <rect class="circle" id="Rectangle-13-Copy-8" stroke="#FFFFFF" opacity="0.239653088" x="0.5" y="0.5" width="47" height="47" rx="23.5"></rect>
                      <g class="path" id="icons/24/facebook" transform="translate(12.000000, 12.000000)" fill="#FFFFFF">
                        <path d="M17.4759184,7.70889796 L13.7314286,7.70889796 L13.7314286,5.25306122 C13.7314286,4.33077551 14.3426939,4.1157551 14.7732245,4.1157551 C15.2027755,4.1157551 17.4156735,4.1157551 17.4156735,4.1157551 L17.4156735,0.0612244898 L13.7764898,0.0470204082 C9.73665306,0.0470204082 8.81730612,3.07102041 8.81730612,5.00620408 L8.81730612,7.70889796 L6.48097959,7.70889796 L6.48097959,11.8868571 L8.81730612,11.8868571 C8.81730612,17.2486531 8.81730612,23.7090612 8.81730612,23.7090612 L13.7314286,23.7090612 C13.7314286,23.7090612 13.7314286,17.1849796 13.7314286,11.8868571 L17.0473469,11.8868571 L17.4759184,7.70889796 Z" id="Shape"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg></a></li>
          </ul>
        </div>
        <div id="block-footerlogoblock" class="block block-educam-helper block-footerlogo-block">


          <a href="https://www.educam.be/nl"><img alt="educam" title="educam" src="https://www.educam.be/themes/educam/images/logo--footer.svg"></a>
        </div>

      </div>

      <div class="region region-footer-bottom">
        <div id="block-footerblock" class="block block-educam-helper block-footer-block">


          ©2021 Educam
        </div>
        <nav role="navigation" aria-labelledby="block-legalmenu-menu" id="block-legalmenu" class="block block-menu navigation menu--legal-menu">

          <h2 class="visually-hidden" id="block-legalmenu-menu">Legal menu</h2>



          <ul class="menu">
            <li class="menu-item">
              <a href="https://www.educam.be/nl/cookies">Cookies</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/nl/privacy-0">Privacy</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/nl/gdpr">GDPR</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/nl/algemene-verkoopsvoorwaarden">Algemene verkoopsvoorwaarden</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/nl/legal-notice">Legal notice</a>
            </li>
          </ul>



        </nav>

      </div>

    </b-container>

    <b-container v-if="lang === 'fr'">
      <div class="region region-footer-menus">
        <nav role="navigation" aria-labelledby="block-voet-menu" id="block-voet" class="block block-menu navigation menu--footer">

          <h2 class="visually-hidden" id="block-voet-menu">Voet</h2>


          <div class="inner">



            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/fr/formations">Notre offre</a>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/fr/notre-offre/apprendre-et-se-former/technics-lab">Technics Lab</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/notre-offre/apprendre-et-se-former/business-lab">Business Lab</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/formations">Toutes les formations</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/notre-offre/apprendre-et-se-former/calendrier-des-formations">Calendrier des formations</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/notre-offre/analyser-evaluer">Analyse &amp; évaluation</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/notre-offre/services">Services</a>
                </li>

              </ul>



            </div>


            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/fr/certification">Certification</a>
              </div>




              <div class="menu-title">
                <a href="https://www.educam.be/fr/soutien-sectoriel">Soutien sectoriel</a>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/fr/soutien-sectoriel/gestion-rh">Gestion RH</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/soutien-sectoriel/cct-mesures-daide">CCT &amp; mesures d'aide</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/soutien-sectoriel/apprendre-et-travailler">Apprendre et travailler</a>
                </li>

              </ul>



            </div>


            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/fr/partage-des-connaissances">Partages des connaissances</a>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/fr/partage-des-connaissances/projets-partenaires">Projets &amp; Partenaires</a>
                </li>

              </ul>






              <div class="menu-title">
                <span>À propos de nous</span>
              </div>
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://www.educam.be/fr/qui-sommes-nous-mission-vision-et-valeurs">Qui sommes nous?</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/propos-de-nous/centres-de-formation">Centres de formation</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/propos-de-nous/nos-formateurs">Nos formateurs</a>
                </li>

                <li class="menu-item">
                  <a href="https://www.educam.be/fr/propos-de-nous/travailler-chez-educam">Travailler chez EDUCAM</a>
                </li>

              </ul>



            </div>


            <div class="footer-col">


              <div class="menu-title">
                <a href="https://www.educam.be/fr/contact">Contact</a>
              </div>

            </div>





          </div>
        </nav>

      </div>

      <div class="region region-footer">
        <div id="block-socialblock" class="block block-educam-helper block-social-block">


          <ul class="social-menu">
            <li><a class="icon-linkedin" href="https://www.linkedin.com/company/educamservice" target="_blank">
              <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Footer-2" transform="translate(-898.000000, -464.000000)">
                    <g id="Group-7" transform="translate(898.000000, 464.000000)">
                      <rect class="circle" id="Rectangle-13-Copy-7" stroke="#FFFFFF" opacity="0.239653088" x="0.5" y="0.5" width="47" height="47" rx="23.5"></rect>
                      <g class="path" id="icons/24/linkedin" transform="translate(12.000000, 12.000000)" fill="#FFFFFF">
                        <path d="M3.6780613,1.04736328 C5.1275482,1.04736328 6.30461982,2.2244349 6.30461982,3.67477044 C6.30461982,5.12595463 5.1275482,6.30302625 3.6780613,6.30302625 C2.22263388,6.30302625 1.04895684,5.12595463 1.04895684,3.67477044 C1.04895684,2.2244349 2.22263388,1.04736328 3.6780613,1.04736328 L3.6780613,1.04736328 Z M1.40963128,22.8745313 L5.94479403,22.8745313 L5.94479403,8.29479777 L1.40963128,8.29479777 L1.40963128,22.8745313 Z" id="Fill-1"></path>
                        <path d="M8.78733288,8.29479777 L13.1307017,8.29479777 L13.1307017,10.2882666 L13.1926528,10.2882666 C13.7968886,9.1417462 15.2760781,7.93327469 17.4808597,7.93327469 C22.0677898,7.93327469 22.9155869,10.9519075 22.9155869,14.8785913 L22.9155869,22.8745313 L18.3863647,22.8745313 L18.3863647,15.7840963 C18.3863647,14.093594 18.3558134,11.918515 16.0305241,11.918515 C13.6729863,11.918515 13.3131605,13.7609249 13.3131605,15.6635886 L13.3131605,22.8745313 L8.78733288,22.8745313 L8.78733288,8.29479777 Z" id="Fill-4"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg></a></li>
            <li><a class="icon-facebook" href="https://www.facebook.com/EducamService/" target="_blank">
              <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Footer-2" transform="translate(-961.000000, -479.000000)">
                    <g id="Group-5" transform="translate(961.000000, 479.000000)">
                      <rect class="circle" id="Rectangle-13-Copy-8" stroke="#FFFFFF" opacity="0.239653088" x="0.5" y="0.5" width="47" height="47" rx="23.5"></rect>
                      <g class="path" id="icons/24/facebook" transform="translate(12.000000, 12.000000)" fill="#FFFFFF">
                        <path d="M17.4759184,7.70889796 L13.7314286,7.70889796 L13.7314286,5.25306122 C13.7314286,4.33077551 14.3426939,4.1157551 14.7732245,4.1157551 C15.2027755,4.1157551 17.4156735,4.1157551 17.4156735,4.1157551 L17.4156735,0.0612244898 L13.7764898,0.0470204082 C9.73665306,0.0470204082 8.81730612,3.07102041 8.81730612,5.00620408 L8.81730612,7.70889796 L6.48097959,7.70889796 L6.48097959,11.8868571 L8.81730612,11.8868571 C8.81730612,17.2486531 8.81730612,23.7090612 8.81730612,23.7090612 L13.7314286,23.7090612 C13.7314286,23.7090612 13.7314286,17.1849796 13.7314286,11.8868571 L17.0473469,11.8868571 L17.4759184,7.70889796 Z" id="Shape"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg></a></li>
          </ul>
        </div>
        <div id="block-footerlogoblock" class="block block-educam-helper block-footerlogo-block">


          <a href="https://www.educam.be/fr"><img alt="educam" title="educam" src="https://www.educam.be/themes/educam/images/logo--footer.svg"></a>
        </div>

      </div>

      <div class="region region-footer-bottom">
        <div id="block-footerblock" class="block block-educam-helper block-footer-block">


          ©2021 Educam
        </div>
        <nav role="navigation" aria-labelledby="block-legalmenu-menu" id="block-legalmenu" class="block block-menu navigation menu--legal-menu">

          <h2 class="visually-hidden" id="block-legalmenu-menu">Legal menu</h2>



          <ul class="menu">
            <li class="menu-item">
              <a href="https://www.educam.be/fr/cookies">Cookies</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/fr/politique-de-confidentialite">Vie privée</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/fr/rgdp">RGDP</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/fr/conditions-generales-de-vente">Conditions générales</a>
            </li>
            <li class="menu-item">
              <a href="https://www.educam.be/fr/mentions-legales">Mentions légales</a>
            </li>
          </ul>



        </nav>

      </div>

    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    lang: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
