import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import i18nConfig from '@/i18n/i18n'
import * as VueGoogleMaps from 'vue2-google-maps'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/style.css'

Vue.use(BootstrapVue, VueAxios, axios)
Vue.use(VueI18n)
Vue.use(IconsPlugin)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY
  }
})

Vue.config.productionTip = false

const i18n = new VueI18n(i18nConfig)

i18n.locale = (localStorage.getItem('language')) ? localStorage.getItem('language') : ((navigator.language === 'fr-fr') ? 'fr' : i18nConfig.locale)

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
