import EducamGateway from '../axios/educam-gateway.instance'

class AuthToken {
  store (tokenData) {
    return new Promise((resolve) => {
      const d = new Date(Date.now())
      d.setSeconds(d.getSeconds() + tokenData.expires_in)
      tokenData.expiryDate = d

      localStorage.setItem('authToken', JSON.stringify(tokenData))
      resolve(tokenData)
    })
  }

  async get () {
    const tokenData = JSON.parse(localStorage.getItem('authToken'))

    return new Promise((resolve) => {
      if (tokenData !== null && tokenData !== undefined) {
        const currentDate = new Date(Date.now())
        let dateCheck = false
        const tokenDate = new Date(tokenData.expiryDate)
        dateCheck = tokenDate > currentDate

        if (!dateCheck) {
          resolve(EducamGateway.post('oauth', {
            grant_type: 'client_credentials'
          }).then(async (data) => {
            return await this.store(data.data)
          }).catch((error) => {
            console.log(error)
          }))
        }

        resolve(tokenData)
      } else {
        resolve(EducamGateway.post('oauth', {
          grant_type: 'client_credentials'
        }).then(async (data) => {
          return await this.store(data.data)
        }).catch((error) => {
          console.log(error)
        }))
      }

      resolve(false)
    })
  }

  clear () {
    localStorage.removeItem('authToken')
  }
}

export default AuthToken
