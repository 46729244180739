<template>
  <b-container>
    <b-row class="company-block">
      <b-col>
        <CourseIntroCard v-if="course !== ''" :course="course.content[language.toUpperCase()]" :image="course.image" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <h2>{{ $t('CONTENT') }}</h2>
        <div v-html="courseContent"></div>
        <div v-if="registrationUrl !== ''">
          <a :href="registrationUrl" target="_blank" class="btn btn-secondary">{{ $t('REGISTRATION_LBL') }}</a>
        </div>
      </b-col>
      <b-col md="6" class="company-info no-padding">
        <h2 class="p-3">{{ $t('EOV') }}</h2>
        <b-row class="contact-card m-3">
          <b-col>
            <h5>{{ companyTitle }}</h5>
            <div>
              <p>
                {{ address1 }}<br />
                {{ address2 }}
              </p>
              <p class="no-padding" v-if="website !== ''"><a :href="website" target="_blank">{{ website.replace('https://', '').replace('http://', '') }}</a></p>
              <p v-if="email !== ''"><a :href="'mailto:' + email">{{ email }}</a></p>
              <ul class="list-unstyled list-inline">
                <li v-if="facebook !== ''"><a :href="facebook" target="_blank"><b-icon-facebook variant="primary" scale="2.5" v-if="facebook !== ''"></b-icon-facebook></a></li>
                <li v-if="linkedin !== ''"><a :href="linkedin" target="_blank"><b-icon-linkedin variant="primary" scale="2.5" v-if="linkedin !== ''"></b-icon-linkedin></a></li>
                <li v-if="instagram !== ''"><a :href="instagram" target="_blank"><b-icon-instagram variant="primary" scale="2.5"></b-icon-instagram></a></li>
              </ul>
            </div>
          </b-col>
          <b-col class="no-padding">
            <GmapMap :center="{lat:lat, lng:lng}"
                     :zoom="10"
                     style="width:100%;height:100%;">
              <GmapMarker
                  :position="{lat:lat, lng:lng}"
                  :clickable="true"
                  :draggable="true"
                  @click="center={lat:lat, lng:lng}"
              />
            </GmapMap>
          </b-col>
        </b-row>
        <b-row v-if="aboutImage !== '' && aboutTitle !== '' && aboutContent !== ''" class="no-padding mt-5 mb-5">
          <b-col md="6">
            <b-img :src="aboutImage" fluid-grow></b-img>
          </b-col>
          <b-col md="6">
            <h5 class="no-padding">{{ aboutTitle }}</h5>
            <div v-html="aboutContent"></div>
          </b-col>
        </b-row>
        <b-row v-if="whatImage !== '' && whatTitle !== '' && whatContent !== ''" class="no-padding mt-5 mb-5">
          <b-col md="6">
            <h5 class="no-padding">{{ whatTitle }}</h5>
            <div v-html="whatContent"></div>
          </b-col>
          <b-col md="6" class="no-padding">
            <b-img :src="whatImage" fluid-grow></b-img>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import CourseIntroCard from "./CourseIntroCard"
import {gmapApi} from 'vue2-google-maps'
import axios from "axios";

export default {
  computed: {
    google: gmapApi
  },
  name: "CourseDetail",
  components: {CourseIntroCard},
  data () {
    return {
      language: this.$i18n.locale,
      course: '',
      company: '',
      courseContent: '',
      registrationUrl: '',
      companyTitle: '',
      address1: '',
      address2: '',
      website: '',
      email: '',
      facebook: '',
      linkedin: '',
      instagram: '',
      aboutTitle: '',
      aboutContent: '',
      aboutImage: '',
      whatTitle: '',
      whatContent: '',
      whatImage: '',
      lat: 0,
      lng: 0
    }
  },
  beforeMount: function () {
    EducamApi.get('/eov/courses/' + this.$route.params.id).then(response => {
      this.course = response.data.detail
      this.courseContent = response.data.detail.content[this.language.toUpperCase()].content
      this.registrationUrl = response.data.detail.content[this.language.toUpperCase()].registration_url
    }).catch(err => {
      console.log(err)
    })

    EducamApi.get('/eov/company/' + this.$route.params.company).then(response => {
      this.company = response.data.detail
      this.companyTitle = response.data.detail.contact.title
      this.address1 = response.data.detail.contact.street + ' ' + response.data.detail.contact.number
      this.address2 = response.data.detail.contact.postal_code + ' ' + response.data.detail.contact.town
      this.email = response.data.detail.contact.email
      this.website = (response.data.detail.contact.website !== '' && response.data.detail.contact.website.substr(0, 4) !==  'http') ? 'https://' + response.data.detail.contact.website : response.data.detail.contact.website
      this.facebook = response.data.detail.contact.facebook
      this.linkedin = response.data.detail.contact.linkedin
      this.instagram = response.data.detail.contact.instagram
      this.aboutTitle = (response.data.detail.information.about_title[this.language.toUpperCase()].content !== undefined && response.data.detail.information.about_title[this.language.toUpperCase()].content !== '') ? response.data.detail.information.about_title[this.language.toUpperCase()].content : ''
      this.aboutContent = (response.data.detail.information.about_content[this.language.toUpperCase()].content !== undefined && response.data.detail.information.about_content[this.language.toUpperCase()].content !== '') ? response.data.detail.information.about_content[this.language.toUpperCase()].content : ''
      this.aboutImage = (response.data.detail.information.about_image.NL.content !== undefined && response.data.detail.information.about_image.NL.content !== '') ? response.data.detail.information.about_image.NL.content : ''
      this.whatTitle = (response.data.detail.information.what_title[this.language.toUpperCase()].content !== undefined && response.data.detail.information.what_title[this.language.toUpperCase()].content !== '') ? response.data.detail.information.what_title[this.language.toUpperCase()].content : ''
      this.whatContent = (response.data.detail.information.what_content[this.language.toUpperCase()].content !== undefined && response.data.detail.information.what_content[this.language.toUpperCase()].content !== '') ? response.data.detail.information.what_content[this.language.toUpperCase()].content : ''
      this.whatImage = (response.data.detail.information.what_image.NL.content !== undefined && response.data.detail.information.what_image.NL.content !== '') ? response.data.detail.information.what_image.NL.content : ''

      axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=pastoriestraat+43,2340+beerse&key=AIzaSyBHuTB_k7pErlW96gg1Mw4tK9jIlKIVqvM').then(response => {
        this.lat = (response.data.results[0].geometry.location.lat !== undefined) ? response.data.results[0].geometry.location.lat : 0
        this.lng = (response.data.results[0].geometry.location.lng !== undefined) ? response.data.results[0].geometry.location.lng : 0
      }).catch(err => {
        console.log(err)
      })
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>
.no-padding {
  padding:0px;
  margin:0px;
}

.company-info {
  background:#FFFFFF;
  margin-top:-110px;
  padding-top:110px;
}

p a {
  text-decoration:underline;
  color:#404957;
}

.text-primary {
  color:#384659 !important;
}

ul li a {
  display:block;
  width:40px;
  height:45px;
  padding:10px;
}

.list-inline li {
  display:inline-block;
  margin-right:15px;
}

.contact-card {
  border:1px solid #CCCCCC;
  margin:0px;
}
</style>
