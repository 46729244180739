<template>
  <b-col md="4" sm="6" v-if="course.image" class="mb-5">
    <a :href="'/course/' + courseId + '/' + company">
      <b-img :src="course.image" fluid />
    </a>
    <h5>{{ course.title }}</h5>
    <div v-html="course.intro"></div>
    <a class="btn btn-secondary" :href="'/course/' + courseId + '/' + company">{{ $t('MORE_INFO') }}</a>
  </b-col>
</template>

<script>
export default {
  name: "CourseCard",
  props: {
    course: {
      required: true
    },
    company: {
      required: true,
      type: Number
    },
    courseId: {
      required: true,
      type: Number
    }
  }
}
</script>

<style scoped>

</style>
