<template>
  <div class="position-relative">
    <div v-if="validateCompany(company)" class="img-holder position-relative">
      <b-img :src="image" fluid-grow class="image-style-header-afbeelding"></b-img>
    </div>
    <div class="company-info position-absolute">
      <h1>{{ company.contact.title }}</h1>
      <div v-html="info" class="clearfix text-formatted field field--name-field-inleiding field--type-text-long field--label-hidden field__item"></div>
      <router-link v-if="more" class="btn btn-secondary btn-moreinfos" :to="{ name: 'CompanyDetail', params: { id: company.id }}">{{ $t('MORE_INFO') }}</router-link>
      <div class="search-box position-absolute" v-if="search">
        <b-form-input v-model="searchValue" class="search-field" :placeholder="$t('TAGS_SEARCH')"></b-form-input>
        <b-button variant="primary" @click="searchCourses" class="search-button"><b-icon-search /></b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ListCard",
  data () {
    return {
      language: this.$i18n.locale,
      image: '',
      info: '',
      searchValue: ''
    }
  },
  props: {
    company: {
      required: true
    },
    more: {
      required: false,
      default: true
    },
    search: {
      required: false,
      default: false
    }
  },
  methods: {
    validateCompany(company) {
      if (
          company.information.intro_image.NL.content !== '' &&
          company.information.intro_content[this.language.toUpperCase()].content !== ''
      ) {
        this.image = company.information.intro_image.NL.content
        this.info = company.information.intro_content[this.language.toUpperCase()].content
        return true
      }
      return false
    },
    searchCourses() {
      this.$emit('search', this.searchValue)
    }
  }
}
</script>

<style scoped>
.company-info {
  background:#fcd61a;
  z-index:100;
  width:90%;
  left:5%;
  padding:10px;
  top:230px;
  height: 250px;
}
.field--name-field-inleiding {
  max-height: 130px;
  overflow: auto;
}
.img-holder.position-relative {
  height: 300px;
  overflow: hidden;
}
.btn-moreinfos {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
h1 {
  font-size:30px;
}
</style>
