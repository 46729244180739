<template>
  <b-row>
    <div class="node node--type-page node--view-mode-header photo_intro ds-2col">
      <div class="group-left">
        <h1>{{ course.title }}</h1>
        <div v-html="course.intro" class="clearfix text-formatted field field--name-field-inleiding field--type-text-long field--label-hidden field__item"></div>
        <div>
          <b-badge variant="primary" v-for="tag in course.tags.split(';')" :key="tag">{{ tag }}</b-badge>
        </div>
      </div>
      <div class="group-right">
        <div class="field field-name-field-header-afbeelding">
          <b-img :src="image" class="image-style-header-afbeelding"></b-img>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>

export default {
  name: "CourseIntroCard",
  props: {
    course: {
      required: true
    },
    image: {
      required: true
    }
  }
}
</script>

<style scoped>
.node--type-page.node--view-mode-header {
  max-width: 1400px;
  margin-left: auto;
  margin-right:auto
}

@media (max-width: 1540px) and(min-width: 961px) {
  .node--type-page.node--view-mode-header {
    padding-left: 100px;
    padding-right:100px
  }
}

@media (max-width: 960px) {
  .node--type-page.node--view-mode-header {
    padding-left: 20px;
    padding-right:20px
  }
}

.node--type-page.node--view-mode-header p:first-child {
  margin-top:0
}

.node--type-page.node--view-mode-header p:last-child {
  margin-bottom:0
}

.node--type-page.node--view-mode-header.photo_intro {
  position:relative
}

@media (max-width: 767px) {
  .node--type-page.node--view-mode-header.photo_intro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative;
    top: -74px;
    margin-bottom: -74px;
    padding-left: 0;
    padding-right:0
  }
}

.node--type-page.node--view-mode-header.photo_intro > .group-left {
  z-index: 2;
  position: relative;
  background: #fcd61a;
  color: #384659;
  border-radius:3px
}

@media (min-width: 768px) {
  .node--type-page.node--view-mode-header.photo_intro > .group-left {
    padding: 55px 75px;
    max-width: 750px;
    left:-75px
  }
}

@media (max-width: 767px) {
  .node--type-page.node--view-mode-header.photo_intro > .group-left {
    padding: 24px 20px 30px;
    width: calc(100% - 20px);
    margin-top:-30px
  }
}

@media (min-width: 768px) {
  .node--type-page.node--view-mode-header.photo_intro > .group-left h1 {
    margin-bottom:23px
  }
}

@media (max-width: 767px) {
  .node--type-page.node--view-mode-header.photo_intro > .group-left h1 {
    margin-bottom:15px
  }
}

.field-name-field-header-afbeelding {
  max-width: 100%;
  overflow: hidden;
}
.field-name-field-header-afbeelding img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .node--type-page.node--view-mode-header.photo_intro > .group-right {
    position: absolute;
    right: -200px;
    top: -115px;
    z-index:1
  }
  .field-name-field-header-afbeelding {
    max-width: 1000px;
    max-height: 500px;
    overflow: hidden;
  }
}

.node--type-page.node--view-mode-header.default h1 {
  max-width:680px
}

.node--type-page.node--view-mode-header.default_intro h1 {
  margin-bottom:23px
}

.node--type-page.node--view-mode-content {
  background:#fff
}

.node--type-page.node--view-mode-content .field--name-field-paragrafen-onderkant {
  max-width: 1400px;
  margin-left: auto;
  margin-right:auto
}

@media (max-width: 1540px) and(min-width: 961px) {
  .node--type-page.node--view-mode-content .field--name-field-paragrafen-onderkant {
    padding-left: 100px;
    padding-right:100px
  }
}

@media (max-width: 960px) {
  .node--type-page.node--view-mode-content .field--name-field-paragrafen-onderkant {
    padding-left: 20px;
    padding-right:20px
  }
}

@media (min-width: 768px) {
  .node--type-page.node--view-mode-content .field--name-field-paragrafen-onderkant {
    padding-top:110px
  }
}

@media (max-width: 767px) {
  .node--type-page.node--view-mode-content .field--name-field-paragrafen-onderkant {
    padding-top:55px
  }
}

.node--type-page.node--view-mode-full .field--name-field-paragraphs {
  position: relative;
  z-index:1
}

@media (min-width: 769px) {
  .node--type-page.node--view-mode-full > .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items:flex-start
  }
}

@media (min-width: 1025px) {
  .node--type-page.node--view-mode-full > .inner > .left {
    width:calc(100% - 590px)
  }
}

@media (max-width: 1024px) and(min-width: 769px) {
  .node--type-page.node--view-mode-full > .inner > .left {
    width:calc(100% - 390px)
  }
}

@media (max-width: 768px) {
  .node--type-page.node--view-mode-full > .inner > .left {
    margin-bottom:40px
  }
}

.node--type-page.node--view-mode-full > .inner > .right {
  background: #fff;
  padding:31px
}

.node--type-page.node--view-mode-full > .inner > .right p:first-child {
  margin-top:0
}

@media (min-width: 769px) {
  .node--type-page.node--view-mode-full > .inner > .right {
    margin-left:auto
  }
}

@media (max-width: 768px) {
  .node--type-page.node--view-mode-full > .inner > .right {
    max-width:400px
  }
}

@media (min-width: 1025px) {
  .node--type-page.node--view-mode-full > .inner > .right {
    width:440px
  }
}

@media (max-width: 1024px) and(min-width: 769px) {
  .node--type-page.node--view-mode-full > .inner > .right {
    width:350px
  }
}

.node--type-nieuws.node--view-mode-header {
  max-width: 820px;
  margin-left: auto;
  margin-right:auto
}

@media (max-width: 840px) {
  .node--type-nieuws.node--view-mode-header {
    padding-left: 20px;
    padding-right:20px
  }
}

.node--type-nieuws.node--view-mode-header h1 {
  margin-bottom:12px
}

@media (min-width: 601px) {
  .node--type-nieuws.node--view-mode-teaser > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display:flex
  }
}

@media (max-width: 600px) {
  .node--type-nieuws.node--view-mode-teaser > a {
    display:block
  }
}

.no-touch .node--type-nieuws.node--view-mode-teaser > a:hover h3 {
  color:#3d6fb4
}

.no-touch .node--type-nieuws.node--view-mode-teaser > a:hover img {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform:scale(1.02)
}

.node--type-nieuws.node--view-mode-teaser img {
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  width:100%
}

.node--type-nieuws.node--view-mode-teaser .field-name-field-overzichtsafbeelding {
  overflow: hidden;
  border-radius:3px
}

.node--type-nieuws.node--view-mode-teaser h3 {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition:all .2s
}

@media (min-width: 601px) {
  .node--type-nieuws.node--view-mode-teaser h3 {
    font-size: 20px;
    line-height:28px
  }
}

@media (max-width: 600px) {
  .node--type-nieuws.node--view-mode-teaser h3 {
    font-size: 18px;
    line-height:26px
  }
}

@media (min-width: 601px) {
  .node--type-nieuws.node--view-mode-teaser .group-left {
    width:266px
  }
}

@media (max-width: 600px) {
  .node--type-nieuws.node--view-mode-teaser .group-left {
    margin-bottom:12px
  }
}

@media (min-width: 601px) {
  .node--type-nieuws.node--view-mode-teaser .group-right {
    margin-left: auto;
    width: calc(100% - 310px);
    border-bottom:1px solid #d9d9d5
  }
}

@media (max-width: 600px) {
  .node--type-nieuws.node--view-mode-teaser .group-right {
    padding-right:20px
  }
}

@media (min-width: 601px) {
  .node--type-nieuws.node--view-mode-teaser .field-name-node-title {
    margin-bottom:15px
  }
}

@media (max-width: 600px) {
  .node--type-nieuws.node--view-mode-teaser .field-name-node-title {
    margin-bottom:7px
  }
}

.node--type-nieuws.node--view-mode-full {
  position:relative
}

@media (max-width: 840px) {
  .node--type-nieuws.node--view-mode-full {
    padding:0
  }
}

.node--type-nieuws.node--view-mode-full > .group-left {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 160px;
  position:relative
}

.node--type-nieuws.node--view-mode-full > .group-left:after {
  height: 1px;
  width: calc(100% + 290px + 290px);
  position: absolute;
  left: -290px;
  bottom: 80px;
  content: '';
  display: block;
  background:#d9d9d5
}

.node--type-nieuws.node--view-mode-full .field--name-field-inleiding {
  font-weight: 600;
  margin-bottom:40px
}

.node--type-nieuws.node--view-mode-full .field--name-field-inleiding p:first-child {
  margin-top:0
}

.node--type-nieuws.node--view-mode-full .field--name-field-inleiding p:only-child {
  margin:0
}

.node--type-nieuws.node--view-mode-full > .group-right {
  position: absolute;
  right: -160px;
  top:72px
}

.node--type-nieuws.node--view-mode-full .field--name-field-paragraphs {
  margin-bottom:40px
}

.node--type-nieuws.node--view-mode-full .field--name-field-thema {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform:uppercase
}

.node--type-nieuws.node--view-mode-full .field--name-field-thema .field__item {
  color: #fcd61a;
  border-radius: 3px;
  background: #384659;
  padding:11px 16px 11px
}

.node--type-nieuws.node--view-mode-full .field--name-field-thema .field__item:not(:last-child) {
  margin-right:8px
}

.node--type-nieuws.node--view-mode-full > .group-footer {
  max-width: 1160px;
  margin:0 auto
}

.node--type-nieuws.node--view-mode-full > .group-footer .field-name-kickstart-utils-nodeviewfield {
  margin-bottom:40px
}

.field-name-kickstart-utils-nodebacktooverview {
  text-align:right
}

.field-name-kickstart-utils-nodebacktooverview a {
  background-color: #fcd61a;
  color: #384659;
  border: none;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  font-weight: 600;
  padding:16px 22px 14px
}

.no-touch .field-name-kickstart-utils-nodebacktooverview a:hover {
  color: #fcd61a;
  background-color:#384659
}

.node--type-partner.node--view-mode-teaser > a {
  display:block
}

.no-touch .node--type-partner.node--view-mode-teaser > a:hover .field-name-node-title {
  -webkit-box-shadow: inset 0 -25px #fcd61a;
  box-shadow:inset 0 -25px #fcd61a
}

.node--type-partner.node--view-mode-teaser .field-name-field-logo {
  border: 1px solid #d9d9d5;
  border-radius: 3px;
  height: 128px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom:12px
}

.node--type-partner.node--view-mode-teaser .field-name-field-logo img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  max-width:120px
}

.node--type-partner.node--view-mode-teaser .field-name-node-title {
  border: none;
  border-radius: 0;
  background: 0 0;
  font-weight: 600;
  color: #3d6fb4;
  -webkit-box-shadow: inset 0 -2px #fcd61a;
  box-shadow: inset 0 -2px #fcd61a;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
  padding: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transition: -webkit-box-shadow .2s;
  transition: -webkit-box-shadow .2s;
  -o-transition: box-shadow .2s;
  transition: box-shadow .2s;
  transition:box-shadow .2s, -webkit-box-shadow .2s
}

.node--type-partner.node--view-mode-teaser .field-name-node-title:hover {
  color: #384659;
  text-decoration:none
}

.node--type-vacature.node--view-mode-teaser {
  height:100%
}

.node--type-vacature.node--view-mode-teaser a {
  height: 100%;
  display: block;
  padding-bottom:25px
}

.no-touch .node--type-vacature.node--view-mode-teaser a:hover h3 {
  color:#3d6fb4
}

.btn-secondary:hover {
  border: none;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  transition: all .2s;
  font-weight: 600;
  padding: 16px 22px 14px;
  color: #fcd61a;
  background-color: #384659;
  opcaity:0.7;
}
</style>
